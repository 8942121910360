import React, { useEffect, useState, useRef } from "react"
import PropTypes from "prop-types"
import styled from "@emotion/styled"
import { Link } from "gatsby"

const StyledServiceCard = styled(Link)`
  text-decoration: none;
  box-shadow: ${props => props.theme.shadows[3]};
  background: ${props => props.theme.colors.primary.dark};
  border-radius: 4px;
  padding: 8px;
  transition: all 300ms;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  overflow: hidden;

  &:hover {
    box-shadow: ${props => props.theme.shadows[1]};
  }
`

const ServiceCard = ({ children, path = "/" }) => {
  const parentRef = useRef(null)
  const [containerWidth, setContainerWidth] = useState(0)

  useEffect(() => {
    setContainerWidth(parentRef.current.offsetWidth)
  }, [])

  useEffect(() => {
    const handleResize = () => {
      setContainerWidth(parentRef.current.offsetWidth)
    }
    window.addEventListener("resize", handleResize)
    return () => {
      window.removeEventListener("resize", handleResize)
    }
  }, [])

  return (
    <StyledServiceCard
      ref={parentRef}
      style={{ height: containerWidth }}
      to={path}
    >
      {children}
    </StyledServiceCard>
  )
}

ServiceCard.propTypes = {
  children: PropTypes.any,
  path: PropTypes.string,
}

export default ServiceCard
