/* eslint-disable react/prop-types */
import React from "react"
import Img from "gatsby-image"
import styled from "@emotion/styled"
import { useStaticQuery, graphql } from "gatsby"
import { Flex, Box } from "@rebass/grid/dist/emotion"

import ServiceCard from "./ServiceCard"
import Container from "./Container"
import { H4 } from "./StyledHeaders"

const Decoration = styled.div`
  height: 50%;
  background-image: linear-gradient(
    120deg,
    ${props => props.theme.colors.secondary.light} 0%,
    ${props => props.theme.colors.secondary.main} 100%
  );
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  transform: translateY(-50%);

  @media (max-width: ${({ theme }) => theme.breakpoints[1]}) {
    height: 25%;
  }
`

const NonStretchedImage = props => {
  let normalizedProps = props
  if (props.fluid && props.fluid.presentationWidth) {
    normalizedProps = {
      ...props,
      style: {
        ...(props.style || {}),
        maxWidth: props.fluid.presentationWidth,
        margin: "0 auto", // Used to center the image
        position: "absolute",
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
      },
    }
  }

  return <Img {...normalizedProps} />
}

export const fluidImage = graphql`
  fragment fluidImage on File {
    childImageSharp {
      fluid(maxWidth: 512) {
        ...GatsbyImageSharpFluid
        presentationWidth
      }
    }
  }
`

export const pageQuery = graphql`
  query {
    web: file(relativePath: { eq: "web-development.jpg" }) {
      ...fluidImage
    }
    mobile: file(relativePath: { eq: "mobile.jpg" }) {
      ...fluidImage
    }
    alexa: file(relativePath: { eq: "alexa.jpg" }) {
      ...fluidImage
    }
    chat: file(relativePath: { eq: "chat.jpg" }) {
      ...fluidImage
    }
    iot: file(relativePath: { eq: "iot.jpg" }) {
      ...fluidImage
    }
    marketing: file(relativePath: { eq: "marketing.jpg" }) {
      ...fluidImage
    }
    business: file(relativePath: { eq: "business-analytics.jpg" }) {
      ...fluidImage
    }
  }
`

const ServicesList = () => {
  const data = useStaticQuery(pageQuery)

  return (
    <div style={{ position: "relative" }}>
      <Container>
        <Flex flexWrap="wrap" mx={[-2, -3]} mb={"60px"}>
          <Box
            width={[1 / 2, 1 / 4, 1 / 4]}
            px={[1, 3]}
            my={[1, 2]}
            flex="1 1 auto"
          >
            <ServiceCard path="/services/web-development">
              <H4>Web Development</H4>
              <NonStretchedImage {...data.web.childImageSharp} />
            </ServiceCard>
          </Box>
          <Box
            width={[1 / 2, 1 / 4, 1 / 4]}
            px={[1, 3]}
            my={[1, 2]}
            flex="1 1 auto"
          >
            <ServiceCard path="/services/mobile-development">
              <H4>Mobile Development</H4>
              <NonStretchedImage {...data.mobile.childImageSharp} />
            </ServiceCard>
          </Box>
          <Box
            width={[1 / 2, 1 / 4, 1 / 4]}
            px={[1, 3]}
            my={[1, 2]}
            flex="1 1 auto"
          >
            <ServiceCard path="/services/alexa-development">
              <H4>Alexa Skill Development</H4>
              <NonStretchedImage {...data.alexa.childImageSharp} />
            </ServiceCard>
          </Box>
          <Box
            width={[1 / 2, 1 / 4, 1 / 4]}
            px={[1, 3]}
            my={[1, 2]}
            flex="1 1 auto"
          >
            <ServiceCard path="/services/chatbot-development">
              <H4>Chatbot Development</H4>
              <NonStretchedImage {...data.chat.childImageSharp} />
            </ServiceCard>
          </Box>
          {/* <Box
            width={[1 / 2, 1 / 3, 1 / 4]}
            px={[1, 3]}
            my={[1, 2]}
            flex="1 1 auto"
          >
            <ServiceCard path="/services/iot-solutions">
              <H4>IoT solutions</H4>
              <NonStretchedImage {...data.iot.childImageSharp} />
            </ServiceCard>
          </Box>
          <Box
            width={[1 / 2, 1 / 3, 1 / 4]}
            px={[1, 3]}
            my={[1, 2]}
            flex="1 1 auto"
          >
            <ServiceCard path="/services/marketing-strategy">
              <H4>Marketing strategy development</H4>
              <NonStretchedImage {...data.marketing.childImageSharp} />
            </ServiceCard>
          </Box>
          <Box
            width={[1 / 2, 1 / 3, 1 / 4]}
            px={[1, 3]}
            my={[1, 2]}
            flex="1 1 auto"
          >
            <ServiceCard>
              <H4>Business analytics</H4>
              <NonStretchedImage {...data.business.childImageSharp} />
            </ServiceCard>
          </Box> */}
        </Flex>
      </Container>

      <Decoration />
    </div>
  )
}

export default ServicesList
