import React from 'react';
import PropTypes from 'prop-types';

const PlukkeTSVG = ({ primary, background = 'rgba(255, 255, 255, 0)', width, height, ...other }) => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" width={width} height={height} {...other}>
			<path
				d="M 256 26 C 383.025 26 486 128.975 486 256 C 486 383.025 383.025 486 256 486 C 128.975 486 26 383.025 26 256 C 26 128.975 128.975 26 256 26 Z"
				fill={background}
				strokeWidth="5"
				stroke={primary}
			/>
			<g>
				<defs>
					<linearGradient id="idiAonJp6DTg-1150537218" gradientTransform="rotate(30, 0.5, 0.5)">
						<stop offset="0" stopColor="rgb(237, 237, 237)" stopOpacity="1" />
						<stop offset="1" stopColor="hsl(0, 0%, 87%)" stopOpacity="1" />
					</linearGradient>
				</defs>
				<path
					d="M 486 256 C 486 319.513 460.256 377.013 418.635 418.635 C 377.013 460.256 319.513 486 256 486 C 128.975 486 26 383.025 26 256 C 26 224.244 28.625 311.061 71.5 362 C 92.938 387.47 124.422 410.367 157 428 C 189.578 445.633 226.25 452 255 452 C 283.75 452 322.234 442.133 355 428 C 387.766 413.867 414.813 387.47 436.5 362 C 479.875 311.061 486 224.244 486 256 Z"
					fill="url(#idiAonJp6DTg-1150537218)"
				/>
			</g>
			<path
				d="M 256 26 C 383.025 26 486 128.975 486 256 C 486 383.025 383.025 486 256 486 C 128.975 486 26 383.025 26 256 C 26 128.975 128.975 26 256 26 Z"
				fill={background}
				strokeWidth="8"
				stroke={primary}
			/>
			<path
				d="M 385 226 C 401.569 226 415 239.431 415 256 C 415 272.569 401.569 286 385 286 C 368.431 286 355 272.569 355 256 C 355 239.431 368.431 226 385 226 Z"
				fill={primary}
			/>
			<path
				d="M 127 176 C 171.183 176 207 211.817 207 256 C 207 300.183 171.183 336 127 336 C 82.817 336 47 300.183 47 256 C 47 211.817 82.817 176 127 176 Z"
				fill={primary}
			/>
			<path
				d="M 127 226 C 143.569 226 157 239.431 157 256 C 157 272.569 143.569 286 127 286 C 110.431 286 97 272.569 97 256 C 97 239.431 110.431 226 127 226 Z"
				fill="#4bbed2"
			/>
		</svg>
	);
};

PlukkeTSVG.propTypes = {
	primary: PropTypes.string,
	background: PropTypes.string,
	width: PropTypes.oneOfType([ PropTypes.string, PropTypes.number ]),
	height: PropTypes.oneOfType([ PropTypes.string, PropTypes.number ])
};

PlukkeTSVG.defaultProps = {
	primary: 'rgb(0, 67, 114)',
	background: 'rgba(255, 255, 255, 0)',
	width: 250,
	height: 250
};

export default PlukkeTSVG;
