import React from "react"
import styled from "@emotion/styled"
import { Flex, Box } from "@rebass/grid/dist/emotion"

import { hex2rgba } from "../utils"
import Button from "./Button"
import StartUpSVG from "../svg/start-up"
import ReportSVG from "../svg/report"
import CodeSVG from "../svg/code"
import { Link } from "gatsby"

const StyledFeatures = styled.div`
  position: absolute;
  right: 0;
  top: -200px;
  z-index: 2;
  width: 90%;
  min-height: 280px;
  background: ${props => props.theme.colors.common.white};
  box-shadow: 0 0.5rem 1rem
    ${props => hex2rgba(props.theme.colors.secondary.main, 0.15)};
  border-radius: 40px 0 0 8px;
  padding: 32px 40px;

  @media (max-width: ${({ theme }) => theme.breakpoints[1]}) {
    position: initial;
    margin-top: -60px;
    margin-left: auto;
    margin-right: 16px;
    border-radius: 40px 4px 4px 8px;
    padding: 20px 24px;
  }
`

const StyledIconWrapper = styled.div`
  width: 64px;
  height: 64px;
  border-radius: 100%;
  background: ${props => props.theme.colors.secondary.light};
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${props => props.theme.colors.primary.main};
`

const StyledFeatureItem = styled(Box)`
  display: flex;
  flex-direction: column;

  > .head {
    display: flex;
    align-items: center;

    .title {
      font-weight: 700;
      flex: 1;
      margin-left: 16px;
    }
  }

  > .description {
    flex: 1;
    margin: 16px 0 16px 0;
    font-size: 16px;
  }

  > a {
    margin-right: auto;
  }
`

const Features = () => {
  return (
    <StyledFeatures>
      <Flex flexWrap="wrap">
        <StyledFeatureItem
          width={[1, 1 / 3, 1 / 4]}
          mx={[0, 2, 2]}
          my={[2, 0, 0]}
          p={3}
        >
          <div className="head">
            <StyledIconWrapper>
              <StartUpSVG height={48} width={48} />
            </StyledIconWrapper>
            <div className="title">Build your idea</div>
          </div>
          <div className="description">
            We will help you to develop your idea
          </div>
          <Button as={Link} to="/#contact-us" outline="true">
            Build my product
          </Button>
        </StyledFeatureItem>
        <StyledFeatureItem
          width={[1, 1 / 3, 1 / 4]}
          mx={[0, 2, 2]}
          my={[2, 0, 0]}
          p={3}
        >
          <div className="head">
            <StyledIconWrapper>
              <ReportSVG height={48} width={48} />
            </StyledIconWrapper>
            <div className="title">Upgrade your business</div>
          </div>
          <div className="description">
            Take your business to the next level
          </div>
          <Button as={Link} to="/#contact-us" outline="true">
            Help me grow
          </Button>
        </StyledFeatureItem>
        <StyledFeatureItem
          width={[1, 1 / 3, 1 / 4]}
          mx={[0, 2, 2]}
          my={[2, 0, 0]}
          p={3}
        >
          <div className="head">
            <StyledIconWrapper>
              <CodeSVG height={48} width={48} />
            </StyledIconWrapper>
            <div className="title">Integrate a solution</div>
          </div>
          <div className="description">
            Digitize your processes with one of our solutions
          </div>
          <Button as={Link} to="/#contact-us" outline="true">
            Scale my business
          </Button>
        </StyledFeatureItem>
      </Flex>
    </StyledFeatures>
  )
}

export default Features
