import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Container from "../components/Container"
import PlukkeTSVG from "../svg/plukket"
import Features from "../components/features"
import { Flex, Box } from "@rebass/grid/dist/emotion"
import ServicesList from "../components/ServicesList"
import FeaturedServiceCard from "../components/FeaturedServiceCard"
import Button from "../components/Button"
import Hero from "../components/Hero"
import { H1, H2, H3 } from "../components/StyledHeaders"
import StandardSection from "../components/StandardSection"
import { Link } from "gatsby"

const IndexPage = () => {
  return (
    <Layout>
      <SEO title="Home" />

      <Hero>
        <Container>
          <div style={{ maxWidth: 720 }}>
            <H1>Web Development Experts</H1>
          </div>
          <div>
            <H3>Web & Mobile Development</H3>
            <H3>Iot Solutions</H3>
            <H3>Alexa Skills Development</H3>
            <H3>Marketing Strategy Development</H3>
            <H3>Business Analytics</H3>
          </div>
        </Container>
        <div style={{ position: "absolute", left: 20, top: -105 }}>
          <PlukkeTSVG width={640} height={640} style={{ opacity: 0.25 }} />
        </div>
      </Hero>

      <StandardSection>
        <Features />

        <Box id="services" pt={["60px", "220px"]} css={{ textAlign: "center" }}>
          <Container>
            <H2>Services</H2>
            <p>Plukke delivers the best of software products and solutions</p>
          </Container>
        </Box>

        <ServicesList />
      </StandardSection>

      <StandardSection withBackground>
        <Box pt={["60px", "80px"]} css={{ textAlign: "center" }}>
          <Container>
            <H2>Featured Services</H2>
          </Container>
        </Box>

        <Container>
          <Flex flexWrap="wrap" mx={[-2, -3]} pb={"60px"}>
            <Box
              width={[1, 1 / 2, 1 / 2]}
              px={[1, 3]}
              my={[1, 2]}
              flex="1 1 auto"
            >
              <FeaturedServiceCard>
                <h3>
                  Simple powerful <span>Landing Page</span>
                </h3>
                <p>from $499</p>

                <div className="list">
                  <ul>
                    <li>Free Domain (.com) for one year</li>
                    <li>Hosting for one year</li>
                    <li>3 pages</li>
                    <li>Home image slider</li>
                    <li>Online form</li>
                    <li>SEO Optimization</li>
                    <li>Unlimited revisions</li>
                  </ul>
                </div>

                <div style={{ textAlign: "center" }}>
                  <Button as={Link} to="/#contact-us" primary="true">
                    Get a quote
                  </Button>
                </div>
              </FeaturedServiceCard>
            </Box>
            <Box
              width={[1, 1 / 2, 1 / 2]}
              px={[1, 3]}
              my={[1, 2]}
              flex="1 1 auto"
            >
              <FeaturedServiceCard>
                <h3>
                  Customer service <span>Alexa Skill</span>
                </h3>
                <p>from $999</p>
                <div id="list">
                  <ul>
                    <li>Invocation name</li>
                    <li>
                      Logo and branding required for the store to be approved
                    </li>
                    <li>Into or Welcome Intent</li>
                    <li>
                      Help, Error, Reprompt, Retry, Stop and Fallback Intent
                    </li>
                    <li>3 custom Intents</li>
                    <li>Skill Certification for Amazon Store</li>
                    <li>Amazon Skill Deployment</li>
                  </ul>
                </div>
                <div style={{ textAlign: "center" }}>
                  <Button as={Link} to="/#contact-us" primary="true">
                    Get a quote
                  </Button>
                </div>
              </FeaturedServiceCard>
            </Box>
          </Flex>
        </Container>
      </StandardSection>
    </Layout>
  )
}

export default IndexPage
