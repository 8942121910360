import React from "react"
import PropTypes from "prop-types"
import styled from "@emotion/styled"
import { hex2rgba } from "../utils"

const StyledFeaturedServiceCard = styled.div`
  box-shadow: 0 0.5rem 1rem
    ${props => hex2rgba(props.theme.colors.secondary.main, 0.15)};
  border-radius: 4px;
  padding: 40px 32px;
  transition: all 300ms;
  background: white;
  height: 100%;
  display: flex;
  flex-direction: column;

  &:hover {
    box-shadow: 0px 6px 6px -3px ${props => hex2rgba(props.theme.colors.secondary.main, 0.2)},
      0px 10px 14px 1px
        ${props => hex2rgba(props.theme.colors.secondary.main, 0.14)},
      0px 4px 18px 3px
        ${props => hex2rgba(props.theme.colors.secondary.main, 0.12)};
  }

  & p:first-of-type {
    text-align: center;
    color: ${props => props.theme.colors.text.secondary};
    font-size: 16px;
    margin-top: -16px;
  }

  > h3 {
    text-align: center;

    span {
      color: ${props => props.theme.colors.secondary.dark};
    }
  }

  .list {
    flex: 1;
    display: flex;
    align-items: center;
  }

  ul {
    margin-top: 32px;
    margin-bottom: 32px;
    list-style-type: none;
    list-style-position: inside;

    li {
      font-size: 16px;
    }

    li::before {
      content: url("/asterisk.svg");
      font-size: 14px;
      display: inline-block;
      width: 16px;
      margin-right: 16px;
    }
  }
`

const FeaturedServiceCard = ({ children }) => {
  return <StyledFeaturedServiceCard>{children}</StyledFeaturedServiceCard>
}

FeaturedServiceCard.propTypes = {
  children: PropTypes.any,
}

export default FeaturedServiceCard
