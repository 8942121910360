import React from 'react';
import styled from '@emotion/styled';

const StyledSVG = styled.svg`fill: ${(props) => props.customFill || props.theme.colors.primary.main};`;

const ReportSVG = ({ ...props }) => {
	return (
		<StyledSVG viewBox="0 0 64 64" xmlns="http://www.w3.org/2000/svg" {...props}>
			<g id="Report-3" data-name="Report">
				<path d="m61 50h-2v-33a5.006 5.006 0 0 0 -5-5h-1v-7a3 3 0 0 0 -3-3h-36a3 3 0 0 0 -3 3v7h-1a5.006 5.006 0 0 0 -5 5v33h-2a1 1 0 0 0 -1 1v4a3 3 0 0 0 3 3h54a3 3 0 0 0 3-3v-4a1 1 0 0 0 -1-1zm-48-38h38v31a1 1 0 0 1 -1 1h-36a1 1 0 0 1 -1-1zm1-8h36a1 1 0 0 1 1 1v5h-38v-5a1 1 0 0 1 1-1zm-7 13a3 3 0 0 1 3-3h1v29a3 3 0 0 0 3 3h36a3 3 0 0 0 3-3v-29h1a3 3 0 0 1 3 3v33h-50zm29 35a1 1 0 0 1 -1 1h-6a1 1 0 0 1 -1-1zm24 3a1 1 0 0 1 -1 1h-54a1 1 0 0 1 -1-1v-3h22a3 3 0 0 0 3 3h6a3 3 0 0 0 3-3h22z" />
				<path d="m15 6h2v2h-2z" />
				<path d="m19 6h2v2h-2z" />
				<path d="m23 6h2v2h-2z" />
				<path d="m18 22h8a1 1 0 0 0 1-1v-6a1 1 0 0 0 -1-1h-8a1 1 0 0 0 -1 1v6a1 1 0 0 0 1 1zm1-6h6v4h-6z" />
				<path d="m18 32h8a1 1 0 0 0 1-1v-6a1 1 0 0 0 -1-1h-8a1 1 0 0 0 -1 1v6a1 1 0 0 0 1 1zm1-6h6v4h-6z" />
				<path d="m18 42h8a1 1 0 0 0 1-1v-6a1 1 0 0 0 -1-1h-8a1 1 0 0 0 -1 1v6a1 1 0 0 0 1 1zm1-6h6v4h-6z" />
				<path d="m30 32h16a1 1 0 0 0 1-1v-16a1 1 0 0 0 -1-1h-16a1 1 0 0 0 -1 1v16a1 1 0 0 0 1 1zm1-16h14v14h-14z" />
				<path d="m29 34h4v2h-4z" />
				<path d="m35 34h12v2h-12z" />
				<path d="m29 38h18v2h-18z" />
				<path d="m33 24h2v4h-2z" />
				<path d="m37 22h2v6h-2z" />
				<path d="m41 20h2v8h-2z" />
			</g>
		</StyledSVG>
	);
};

export default ReportSVG;
