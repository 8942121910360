import React from "react"
import styled from "@emotion/styled"

const StyledSVG = styled.svg`
  fill: ${props => props.customFill || props.theme.colors.primary.main};
`

const CodeSVG = ({ ...props }) => {
  return (
    <StyledSVG
      enableBackground="new 0 0 504.009 504.009"
      viewBox="0 0 504.009 504.009"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="m149.552 190.425 52.2 39.472c2.652 1.987 6.412 1.449 8.4-1.203 1.976-2.636 1.457-6.372-1.163-8.369l-45.87-34.685 45.87-34.686c2.618-2.031 3.095-5.8 1.064-8.418-1.996-2.573-5.679-3.084-8.301-1.153l-52.2 39.471c-2.643 1.999-3.165 5.762-1.166 8.405.333.44.725.832 1.166 1.166z" />
      <path d="m251.494 228.73c1.999 2.643 5.761 3.166 8.405 1.167l52.2-39.472c2.643-1.999 3.165-5.762 1.166-8.405-.333-.441-.726-.833-1.166-1.166l-52.2-39.471c-2.629-2.017-6.395-1.522-8.413 1.107-2.017 2.629-1.522 6.395 1.107 8.413.022.017.045.034.068.051l45.87 34.686-45.87 34.685c-2.644 1.998-3.166 5.761-1.167 8.405 0-.001 0-.001 0 0z" />
      <path d="m221.017 250.756c3.276.496 6.335-1.758 6.831-5.034v-.001l17.819-117.731c.497-3.277-1.756-6.336-5.033-6.833s-6.336 1.756-6.833 5.033l-17.818 117.735c-.497 3.276 1.757 6.334 5.034 6.831z" />
      <path d="m474.895 181.004h-32.888v-101.953c-.199-24.812-20.277-44.871-45.09-45.047h-332.185c-24.625 0-44.725 20.422-44.725 45.047v237.953h-14.358c-3.154.034-5.683 2.618-5.649 5.772.001.078.003.157.007.235v29.725c-.109 24.34 19.535 44.16 43.875 44.268.142.001.283.001.425 0h288.7v43.142c0 16.25 12.815 29.858 29.065 29.858h112.823c16.25 0 29.112-13.608 29.112-29.858v-229.573c.158-16.172-12.825-29.41-28.997-29.568-.039-.001-.077-.001-.115-.001zm-83.888 0h-28.935c-16.151.098-29.165 13.271-29.066 29.423 0 .049.001.098.001.146v70.431h-263v-194h321zm-46 58h147v163h-147zm147-28.431v16.431h-147v-16.431c0-9.633 7.432-17.569 17.065-17.569h112.823c9.539.087 17.201 7.89 17.114 17.429-.001.046-.002.093-.002.14zm-460-131.522c0-18.008 14.717-33.047 32.725-33.047h332.185c18.188.168 32.899 14.859 33.09 33.047v101.953h-27v-99.728c0-3.313-2.267-6.272-5.58-6.272h-333.205c-3.408.111-6.136 2.863-6.215 6.272v206.137c0 3.314 2.9 5.591 6.215 5.591h268.785v24h-45.469c-1.676.001-3.276.703-4.411 1.937l-7.847 8.525c-6.189 6.703-14.892 10.522-24.015 10.538h-40.881c-9.123-.015-17.826-3.834-24.015-10.536l-7.846-8.521c-1.136-1.236-2.737-1.941-4.416-1.943h-142.1zm12.3 305.953c-17.706.133-32.167-14.113-32.3-31.818-.001-.15-.001-.3 0-.45v-23.732h159.475l6.064 6.594c8.463 9.165 20.364 14.386 32.838 14.406h40.881c12.476-.019 24.378-5.24 32.842-14.406l6.061-6.594h42.839v56zm430.587 73h-112.822c-9.633 0-17.065-8.225-17.065-17.858v-26.142h147v26.142c0 9.633-7.479 17.858-17.112 17.858z" />
      <path d="m398.513 215.004h39.941c3.314 0 6-2.686 6-6s-2.686-6-6-6h-39.941c-3.314 0-6 2.686-6 6s2.686 6 6 6z" />
      <path d="m418.484 417.675c-10.101-.001-18.29 8.188-18.291 18.289s8.188 18.29 18.289 18.291 18.29-8.188 18.291-18.289v-.001c-.012-10.097-8.193-18.278-18.289-18.29zm0 24.579c-3.474.001-6.29-2.815-6.291-6.289s2.815-6.29 6.289-6.291 6.29 2.815 6.291 6.289v.002c-.006 3.47-2.819 6.283-6.289 6.289z" />
      <path d="m388.816 267.004h91.534c3.314 0 6-2.686 6-6s-2.686-6-6-6h-91.534c-3.314 0-6 2.686-6 6s2.686 6 6 6z" />
      <path d="m480.35 285.004h-91.534c-3.314 0-6 2.686-6 6s2.686 6 6 6h91.534c3.314 0 6-2.686 6-6s-2.687-6-6-6z" />
      <path d="m480.35 314.004h-91.534c-3.314 0-6 2.686-6 6s2.686 6 6 6h91.534c3.314 0 6-2.686 6-6s-2.687-6-6-6z" />
      <path d="m480.35 344.004h-91.534c-3.314 0-6 2.686-6 6s2.686 6 6 6h91.534c3.314 0 6-2.686 6-6s-2.687-6-6-6z" />
      <path d="m480.35 374.004h-91.534c-3.314 0-6 2.686-6 6s2.686 6 6 6h91.534c3.314 0 6-2.686 6-6s-2.687-6-6-6z" />
      <path d="m369.997 255.004h-13.38c-3.314 0-6 2.686-6 6s2.686 6 6 6h13.38c3.314 0 6-2.686 6-6s-2.687-6-6-6z" />
      <path d="m369.997 285.004h-13.38c-3.314 0-6 2.686-6 6s2.686 6 6 6h13.38c3.314 0 6-2.686 6-6s-2.687-6-6-6z" />
      <path d="m369.997 314.004h-13.38c-3.314 0-6 2.686-6 6s2.686 6 6 6h13.38c3.314 0 6-2.686 6-6s-2.687-6-6-6z" />
      <path d="m369.997 344.004h-13.38c-3.314 0-6 2.686-6 6s2.686 6 6 6h13.38c3.314 0 6-2.686 6-6s-2.687-6-6-6z" />
      <path d="m369.997 374.004h-13.38c-3.314 0-6 2.686-6 6s2.686 6 6 6h13.38c3.314 0 6-2.686 6-6s-2.687-6-6-6z" />
    </StyledSVG>
  )
}

export default CodeSVG
